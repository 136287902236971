import Vue from "vue";
import Router from "vue-router";
import Index from "./views/Index.vue";
import BaiduMap from "vue-baidu-map";
Vue.use(Router);
Vue.use(BaiduMap, {
  ak: "2UBrGlfw9Vsz4Q2FbXiCb8kuzL2UUa5i" //官方提供的ak秘钥
});
export default new Router({
  //history
  mode: "hash",
  // mode: "history",
  base: process.env.BASE_URL,

  routes: [
    {
      path: "/",
      name: "Index",
      component: Index
    },
    {
      path: "/about",
      name: "about",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/about.vue")
    },
    {
      path: "/List",
      name: "islist",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/List.vue")
    },
    {
      path: "/Content",
      name: "isContent",
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () =>
        import(/* webpackChunkName: "about" */ "./views/Content.vue")
    }
  ]
});
