<template>
  <div class="isIndex" v-loading="loading">
    <isHead></isHead>
    <!-- banner -->
    <el-row>
      <el-col :span="24" class="wow fadeIn">
        <swiper :options="swiperOption" ref="mySwiper">
          <!-- slides -->
          <swiper-slide v-for="(item, index) in isbanner" :key="index">
            <img :src="item.img_url" alt width="100%;" />
            <div class="siziimg">
              <!-- <img src="@/assets/bigtemx.png" alt style="width:45%" /> -->
              <img :src="item.thumb" alt style="width:45%" />

              <div
                style="color:#fff;font-size:1.3rem;font-weight:900;margin-top:5%"
                :style="{ 'font-size': isbanner_text }"
              >
                <span
                  style="fton-weight:bold; font-family: 'FZLTCHJW', sans-serif;"
                  >{{ item.name }}</span
                >
              </div>
            </div>
          </swiper-slide>
          <!-- Optional controls -->
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </el-col>
    </el-row>
    <el-row type="flex" class="row-bg" justify="center">
      <el-col
        :span="24"
        id="anchor-1"
        class="wow fadeInDown"
        ata-wow-delay="300ms"
        data-wow-iteration="1"
        data-wow-duration="2s"
      >
        <div class="gamstetit" style>
          <div class="en">NEW GAMES</div>
          <div class="zh">最新游戏</div>
        </div>
      </el-col>
    </el-row>
    <el-row style="margin:0px;" type="flex" justify="center" :gutter="15">
      <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15" justify="center">
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="8"
          :xl="8"
          style="margin-bottom:15px;"
          v-for="(item, index) in isdata"
          :key="index"
          class="wow fadeIn"
          ata-wow-delay="300ms"
          data-wow-iteration="1"
          data-wow-duration="2s"
        >
          <div
            style="width:100%; background: #ededed;position: relative;"
            @mouseenter="isshowQR(index)"
            @click="isshowQR(index)"
          >
            <transition name="el-zoom-in-bottom">
              <div
                v-show="showQR == index"
                class="transition-box"
                style="position: absolute;background: #ff3f40;width:100%;height:100%;"
              >
                <div style="position: relative;top: 12%;">
                  <img :src="item.qr_url" alt style="max-width:130px;" />
                </div>
              </div>
            </transition>

            <img :src="item.thumb" alt style="width:100%" />
          </div>
          <div style="width:100%; background: #ededed;padding-top:10px">
            <span style="font-weight:900;">{{ item.name }}</span>
            <div style="padding:5px 10px 15px 15px;">
              <div style="font-size:12px;" class="twozi">
                <!-- 第一人称视角的即时竞技类3D射击游戏。多样的比
                赛模式，完美的操作手感，爽快的射击体验！
                赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！-->
                {{ item.content }}
              </div>
            </div>
          </div>
        </el-col>
        <!-- <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" style="margin-bottom:8px;">
          <div
            style="width:100%; background: #ededed;position: relative;"
            @click="isshowQR(index)"
          >
         
            <transition name="el-zoom-in-bottom">
              <div
                v-show="showQR"
                class="transition-box"
                style="position: absolute;background: #ff3f40;width:100%;height:100%;"
              >
              <div style="position: absolute;top: 7%;left:24%">
                <img src="@/assets/qrcode_for_gh_2cbe3e7517f4_258.jpg" alt="" style="max-width:150px;">
              </div>
              </div>
            </transition>

            <img src="@/assets/qmqs.jpg" alt style="width:100%">
          </div>
          <div style="width:100%; background: #ededed;padding-top:10px">
            <span style="font-weight:900;">全民枪神：边境王者</span>
            <div style="padding:5px 10px 15px 15px;">
              <div style="font-size:12px;" class="twozi">
                第一人称视角的即时竞技类3D射击游戏。多样的比
                赛模式，完美的操作手感，爽快的射击体验！
                赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！
              </div>
            </div>
          </div>
        </el-col>-->
      </el-col>
    </el-row>

    <el-row
      type="flex"
      class="row-bg isNews"
      justify="center"
      style="margin-top: 20px;"
    >
      <el-col :span="24" id="anchor-2">
        <div
          class="gamstetit wow fadeInDown"
          ata-wow-delay="300ms"
          data-wow-iteration="1"
          data-wow-duration="2s"
        >
          <div class="en">NEWS</div>
          <div class="zh">最新资讯</div>
        </div>
      </el-col>
    </el-row>
    <el-row
      style="margin:0px;"
      type="flex"
      class="row-bg magin0 isNews"
      justify="center"
      :gutter="20"
    >
      <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15" justify="center">
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
          v-for="(item, index) in isNews"
          :key="index"
          class="wow fadeIn"
          ata-wow-delay="300ms"
          data-wow-iteration="1"
          data-wow-duration="2s"
        >
          <div v-if="item.is_hot == 1">
            <div
              style="width:100%; background: #fff;position: relative;"
              @click="gocent(item.id)"
            >
              <img :src="item.thumb" alt style="width:100%" />
            </div>
            <div
              style="width:100%; background: #fff;padding-top:10px;"
              @click="gocent(item.id)"
            >
              <div style="font-weight:900;text-align: left; padding-left:16px">
                {{ item.title }}
              </div>
              <div style="padding:5px 10px 15px 10px;width:70%;">
                <div style="font-size:14px;" class="twozi">
                  {{ item.description }}
                  <!-- 第一人称视角的即时竞技类3D射击游戏。多样的比
                赛模式，完美的操作手感，爽快的射击体验！
                  赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！-->
                </div>
              </div>
              <div
                style="text-align: left;padding:13px;font-size:.4rem; font-family: 'Helvetica', sans-serif; text-decoration:underline"
              >
                READ MORE
              </div>
            </div>
          </div>
        </el-col>
        <el-col
          :span="24"
          type="flex"
          justify="center"
          style="margin:30px 0px;"
          class="wow fadeIn"
          ata-wow-delay="300ms"
          data-wow-iteration="1"
          data-wow-duration="2s"
        >
          <el-button type="danger" @click="jump">READ MORE</el-button>
        </el-col>
      </el-col>
    </el-row>
    <!-- 关于我们 -->
    <el-row style="margin:0px;" type="flex" class="row-bg" justify="center">
      <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15" justify="center">
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
          style="padding:50px;"
          id="anchor-3"
        >
          <div
            style="width:100%; background: #fff;position: relative;text-align: left;"
          >
            <span
              style=" font-family: 'Helvetica', sans-serif;font-weight:900;font-size:20px;"
              class="wow fadeInDown"
              ata-wow-delay="300ms"
              data-wow-iteration="1"
              data-wow-duration="2s"
              >ABOUT US</span
            >
            <p
              style="padding:5px;font-size:.9rem;margin:0px;"
              class="wow fadeInLeft"
              ata-wow-delay="300ms"
              data-wow-iteration="1"
              data-wow-duration="2s"
            >
              团队核心成员来自于国内顶尖游戏公司
            </p>
            <div
              style="margin-top:5rem;font-size:.9rem;font-weight:900;"
              class="wow fadeInDown"
              ata-wow-delay="300ms"
              data-wow-iteration="1"
              data-wow-duration="2s"
            >
              火花幻境成立于2016年，是一家以提供优质游戏
              和完善的互联网服务为目标的高新技术企业。团
              队的核心成员均来国内一线游戏公司，具备多年
              的移动游戏研发经验，产品研发实力雄厚。旗下
              已成功发布多款不同类型的游戏产品。
            </div>
          </div>
        </el-col>
        <el-col
          :xs="24"
          :sm="24"
          :md="12"
          :lg="12"
          :xl="12"
          style="padding:50px;"
        >
          <div style="width:100%; background: #fff;position: relative;">
            <div
              style="width:50%;position: absolute;left:0;top:8rem;background: #ff3f40;height:150px;z-index:1;"
              class="wow fadeIn"
              ata-wow-delay="300ms"
              data-wow-iteration="1"
              data-wow-duration="1s"
            ></div>
            <div
              style="width:50%;position: absolute;right:0;top:3rem;background: #ff3f40;height:150px;z-index:1;"
              class="wow fadeIn"
              ata-wow-delay="300ms"
              data-wow-iteration="1"
              data-wow-duration="2s"
            ></div>
            <div
              style="position: absolute;right:0;top:50%;height:200px;z-index:9;padding:0px 30px;"
              class="wow fadeInUp"
              ata-wow-delay="300ms"
              data-wow-iteration="1"
              data-wow-duration="2s"
            >
              <img
                src="@/assets/menbanz.png"
                alt
                style="max-height:360px;max-width:100%"
              />
            </div>
          </div>
        </el-col>
      </el-col>
    </el-row>
    <!-- 介绍 -->
    <div style="background: #e45054; margin-top:10%" class="hidden-md-and-down">
      <el-row
        style="padding:10px;"
        type="flex isbj2"
        class="row-bg isbj2"
        justify="center"
        :gutter="15"
      >
        <el-col
          :span="20"
          :offset="2"
          justify="center"
          class="wow fadeInUp"
          ata-wow-delay="300ms"
          data-wow-iteration="1"
          data-wow-duration="2s"
        >
          <!--        :xs="22"
          :sm="22"
          :md="17"
          :lg="17"
          :xl="17"
          :offset="3"-->
          <el-col :span="8">
            <div style="padding:32px;">
              <img
                src="@/assets/186.png"
                alt
                style="max-width:100%;height:50px;"
              />
              <div style="color:#fff;margin-top:20px;font-weight: bold;">
                专注移动游戏
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="padding:32px;">
              <img
                src="@/assets/185.png"
                alt
                style="max-width:100%;height:50px;"
              />
              <div style="color:#fff;margin-top:20px;font-weight: bold;">
                员工福利丰富
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div style="padding:32px;">
              <img
                src="@/assets/184.png"
                alt
                style="max-width:100%;height:50px;"
              />
              <div style="color:#fff;margin-top:20px;font-weight: bold;">
                技术实力雄厚
              </div>
            </div>
          </el-col>
        </el-col>
      </el-row>
    </div>
    <!-- 我们的团队 -->
    <!-- <el-row
      type="flex"
      class="row-bg"
      justify="center"
      style="margin-top: 20px;"
      :style="{marginTop:istmen}"
    >
      <el-col :span="24"  id="anchor-3">
        <div class="gamstetit" style>
          <div class="en">OUR TEAM</div>
          <div class="zh">我们的团队</div>
        </div>
      </el-col>
    </el-row>-->
    <!-- 团队内容 -->
    <!-- <el-row style="margin:0px;" type="flex" class="row-bg" justify="center" :gutter="15">
      <el-col :xs="24" :sm="24" :md="15" :lg="15" :xl="15" justify="center">
        <el-col
          :xs="12"
          :sm="12"
          :md="8"
          :lg="6"
          :xl="6"
          justify="center"
          v-for="(item, index) in isTeam"
          :key="index"
          style="background:#fff;"
        >
          <div style="position:relative;z-index:1; width:98%" @click="isshowjianj(index)">
            <img :src="item.images" alt style="width:100%;">
            <transition name="el-zoom-in-bottom">
              <div
                style="position:absolute; bottom:0px;width:100% ;z-index:1;"
                v-show="shohjianj == index"
              >
                <div style="position:relative;" class="triangle_left"></div>
                <div style="position:relative;padding:10px;background: #ff3f40;color:#fff;">
                  <div style="color:#fff;">
                    <h3 style="text-align: left;margin: 0;">{{item.name}}</h3>
                  </div>
                  <div style>{{item.position}}</div>
                </div>
              </div>
            </transition>
          </div>
        </el-col>
      </el-col>
    </el-row>-->
    <!-- 加入我们 -->
    <el-row
      type="flex"
      class="row-bg isNews"
      justify="center"
      style="margin-top: 20px;"
      :style="{ marginTop: istmen }"
    >
      <el-col
        :span="24"
        id="anchor-4"
        class="wow fadeInDown"
        ata-wow-delay="300ms"
        data-wow-iteration="1"
        data-wow-duration="2s"
      >
        <div class="gamstetit" style>
          <div class="en">JOIN US</div>
          <div class="zh">加入我们</div>
        </div>
      </el-col>
    </el-row>
    <!-- 加我我们内容 -->
    <el-row
      style="margin:0px;padding-bottom:50px;"
      type="flex"
      class="row-bg isNews"
      justify="center"
      :gutter="15"
    >
      <el-col
        :xs="23"
        :sm="23"
        :md="10"
        :lg="10"
        :xl="10"
        justify="center"
        style="position: relative; "
        class="wow fadeIn"
        ata-wow-delay="300ms"
        data-wow-iteration="1"
        data-wow-duration="3s"
      >
        <swiper :options="swiperJOIN" ref="mySwiper">
          <swiper-slide
            v-for="(item, index) in isjoin_us"
            :key="index"
            style="height:300px;"
          >
            <el-row :span="24" style="background: #fff;padding:0;">
              <a :href="item.url" style="color:#2c3e50">
                <el-col
                  :span="8"
                  style="background-position:center;"
                  :style="{
                    background: 'url(' + item.thumb + ')',
                    height: '300px'
                  }"
                >
                  <!--  :style="{ 'background': 'url('+ item.thumb+ ')', 'height':'285px'}" -->
                  <!-- <img src="@/assets/8d6e40878b2c9efb84ab9d60327f2d3c.svg" alt style="width:100%;"> -->
                  <!-- <img :src=" isurl + item.thumb " alt style="width:100%;"> -->
                </el-col>
                <el-col :span="16">
                  <div
                    style="padding:0px 20px;text-align: left;position: relative;"
                  >
                    <h4 style>{{ item.title }}</h4>
                    <div style="font-size:0.9rem;" v-html="item.content">
                      <!-- 岗位职责：
                    <br />1、根据游戏风格，找到合适的参考或资源；
                    <br />2、负责游戏内3D场景（包含地形 、物件、道具等模型）
                    的制作； <br />3、负责资源导入至Unity并对最终效果优化。
                      <br />-->
                      {{ item.content }}
                    </div>
                  </div>
                  <!-- 深圳·1年以内·大专 -->

                  <!-- <div style="position:absolute;bottom:0;background:#fff;">
                  <div style="padding:20px;color:#000;background:#fff;">
                    {{item.description}}
                  </div>
                </div>
                <div style="position:absolute;bottom:0;right:0;">
                  <div style="padding:10px;background:#fff;">
                    <el-button style="border: 1px solid  #ff3f40;color:#000;background:#fff;">JOIN</el-button>
                  </div>
                  </div>-->
                </el-col>
                <!-- 备注细信息 -->
                <el-col
                  :span="16"
                  :offset="8"
                  style="padding:0px;position:absolute;bottom:0;"
                >
                  <el-col :span="12">
                    <div style="padding:20px;color:#000;background:#fff;">
                      {{ item.description }}
                    </div>
                  </el-col>
                  <el-col :span="12" style="line-height: 4rem;">
                    <div style="color:#000;background:#fff;">
                      <!-- {{item.description}} -->
                      <el-button
                        style="border: 1px solid  #ff3f40;color:#000;background:#fff;padding:5px 30px;"
                        >JOIN</el-button
                      >
                    </div>
                  </el-col>
                </el-col>
              </a>
            </el-row>
          </swiper-slide>
        </swiper>
        <div class="swiper-button-next hidden-md-and-down"></div>
        <div
          class="swiper-button-prev swiper-button-prev_q hidden-md-and-down"
        ></div>
      </el-col>
    </el-row>

    <!-- 联系我们 -->
    <el-row class="row-bg isNews" justify="center" style="background:#717171;">
      <el-col
        :xs="16"
        :sm="16"
        :md="16"
        :lg="9"
        :xl="9"
        :offset="5"
        justify="center"
        id="anchor-5"
        style="background:#717171;color:#fff;  height: 400px;"
      >
        <div style="padding:20px;text-align: left;margin-top:50px;">
          <div style>
            <div
              style="font-size:.6rem;"
              class="wow fadeInLeft"
              ata-wow-delay="300ms"
              data-wow-iteration="1"
              data-wow-duration="1s"
            >
              CONTACT US
            </div>
            <div
              style="font-size:2rem;font-weight:900;"
              class="wow fadeInLeft"
              ata-wow-delay="300ms"
              data-wow-iteration="1"
              data-wow-duration="2s"
            >
              联系我们
            </div>
            <div
              style="font-size:.9rem;line-height: 2rem;margin-top:15px;"
              class="wow fadeInLeft"
              ata-wow-delay="300ms"
              data-wow-iteration="1"
              data-wow-duration="3s"
            >
              地址：中国 广东省 深圳市 南山区 深圳湾科技生态园2栋C座
              <br />邮编：518000 <br />邮箱：spark@sparkgame.com.cn
              <br />总机：0755-26606280 <br />传真：-
              <br />
            </div>
          </div>
        </div>
      </el-col>
      <el-col
        :xs="24"
        :sm="24"
        :md="10"
        :lg="10"
        :xl="10"
        justify="center"
        style="background:#717171;color:#fff;"
        class="wow fadeInRight"
        ata-wow-delay="300ms"
        data-wow-iteration="1"
        data-wow-duration="1s"
      >
        <baidu-map
          class="map"
          :center="center"
          :zoom="15"
          :mapStyle="mapStyle"
          @ready="handler"
        >
          <bm-overlay
            pane="labelPane"
            class="active"
            :class="{ sample: true }"
            @draw="draw"
            @mouseover.native="active = true"
            @mouseleave.native="active = false"
          >
            <div>
              <img src="@/assets/195.svg" alt />
            </div>
          </bm-overlay>
        </baidu-map>
      </el-col>
    </el-row>
    <isFooter></isFooter>
  </div>
</template>
<script>
import isFooter from "@/components/Footer.vue";
import isHead from "@/components/Head.vue";
import people from "@/assets/baiduMap.json";
import "animate.css";
import { WOW } from "wowjs";
export default {
  name: "isIndex",
  components: {
    isHead,
    isFooter
  },
  data() {
    return {
      isapi: "",
      loading: true,
      center: { lng: 0, lat: 0 }, //经纬度
      isyuabgon: "60%",
      istmen: "",
      showQR: -1, //显示二维码
      shohjianj: -1,
      active: false,
      isurl: "https://www.sparkgame.com.cn/",

      // banner
      isbanner: [
        // { images: require("@/assets/bj.png") },
        // { images: require("@/assets/bj.png") },
        // { images: require("@/assets/bj.png") },
        // { images: require("@/assets/bj.png") },
        // { images: require("@/assets/bj.png") },
        // { images: require("@/assets/bj.png") }
      ],
      //最新游戏
      isdata: [
        // {
        //   images: require("@/assets/qmqs.jpg"),
        //   qr: require("@/assets/qrcode_for_gh_2cbe3e7517f4_258.jpg"),
        //   text: "全名枪神：边境王者",
        //   summary:
        //     "   第一人称视角的即时竞技类3D射击游戏。多样的比赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！"
        // },
        // {
        //   images: require("@/assets/qmqs.jpg"),
        //   qr: require("@/assets/qrcode_for_gh_2cbe3e7517f4_258.jpg"),
        //   text: "全名枪神：边境王者",
        //   summary:
        //     "   第一人称视角的即时竞技类3D射击游戏。多样的比赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！"
        // },
        // {
        //   images: require("@/assets/qmqs.jpg"),
        //   qr: require("@/assets/qrcode_for_gh_2cbe3e7517f4_258.jpg"),
        //   text: "全名枪神：边境王者",
        //   summary:
        //     "   第一人称视角的即时竞技类3D射击游戏。多样的比赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！"
        // },
        // {
        //   images: require("@/assets/qmqs.jpg"),
        //   qr: require("@/assets/qrcode_for_gh_2cbe3e7517f4_258.jpg"),
        //   text: "全名枪神：边境王者",
        //   summary:
        //     "   第一人称视角的即时竞技类3D射击游戏。多样的比赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！"
        // },
        // {
        //   images: require("@/assets/qmqs.jpg"),
        //   qr: require("@/assets/qrcode_for_gh_2cbe3e7517f4_258.jpg"),
        //   text: "全名枪神：边境王者",
        //   summary:
        //     "   第一人称视角的即时竞技类3D射击游戏。多样的比赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！"
        // }
      ],
      //最新资讯
      isNews: [
        // {
        //   images: require("@/assets/26544.png"),
        //   text: "标题",
        //   summary:
        //     " 第一人称视角的即时竞技类3D射击游戏。多样的比赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！"
        // },
        // {
        //   images: require("@/assets/26544.png"),
        //   text: "标题",
        //   summary:
        //     " 第一人称视角的即时竞技类3D射击游戏。多样的比赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！赛模式，完美的操作手感，爽快的射击体验！"
        // }
      ],
      //我们的团队
      isTeam: [
        {
          images: require("@/assets/shutterstock_1186046488.png"),
          name: "名字",
          position: "火花幻境CEO"
        },
        {
          images: require("@/assets/shutterstock_1186046488.png"),
          name: "名字",
          position: "火花幻境CEO"
        },
        {
          images: require("@/assets/shutterstock_1186046488.png"),
          name: "名字",
          position: "火花幻境CEO"
        },
        {
          images: require("@/assets/shutterstock_1186046488.png"),
          name: "名字",
          position: "火花幻境CEO"
        }
      ],
      isjoin_us: [], //加入我们
      isbanner_imgstyle: "100%",
      isbanner_text: ":1.5rem",
      bannerheight: "auto",
      // banner
      swiperOption: {
        grabCursor: true,
        autoplay: {
          delay: 10000
        },
        pagination: {
          paginationType: "fraction",
          el: ".swiper-pagination",
          dynamicBullets: true,
          dynamicMainBullets: 1
        }
      },
      //
      swiperJOIN: {
        grabCursor: true,
        autoplay: {
          delay: 4000
        },
        navigation: {
          nextEl: ".swiper-button-next", //前进按钮的css选择器或HTML元素。
          prevEl: ".swiper-button-prev", //后退按钮的css选择器或HTML元素。
          hideOnClick: true, //点击slide时显示/隐藏按钮
          disabledClass: "my-button-disabled", //前进后退按钮不可用时的类名。
          hiddenClass: "my-button-hidden" //按钮隐藏时的Class
        }
      },
      //
      mapStyle: {
        styleJson: people
      }
    };
  },
  methods: {
    // 地图大小
    draw({ el, BMap, map }) {
      const pixel = map.pointToOverlayPixel(
        new BMap.Point(113.960238, 22.534904)
      );
      //
      el.style.left = pixel.x - 10 + "px";
      //
      el.style.top = pixel.y - 80 + "px";
    },
    // 地图大小
    handler({ BMap, map }) {
      // console.log(BMap, map);
      this.center.lng = 113.960238;
      this.center.lat = 22.534904;
      this.zoom = this.zoom;
    },
    //获取宽 判断
    getwidht() {
      // console.log(window.innerWidth );
      if (window.innerWidth < 768) {
        //    this.isbanner_imgstyle = "100%"
        this.isbanner_text = "0.8rem";
        // this.isbanner_imgstyle = "%";
      } else {
        // this.isbanner_imgstyle = "45%";
        this.isbanner_text = "1.5rem";
      }
      if (window.innerWidth < 991) {
        this.istmen = "60%";
      } else {
        this.istmen = "";
      }
    },
    //调内容
    gocent(id) {
      this.$router.push({ name: "isContent", params: { isid: id } });
    },
    // 跳到列表
    jump() {
      this.$router.push({ name: "islist" });
    },
    isshowQR(e) {
      this.showQR = this.showQR == e ? -1 : e;
    },
    isshowjianj(e) {
      this.shohjianj = this.shohjianj == e ? -1 : e;
    },
    //
    //
    // 渲染bnneer
    renderingBanner() {
      this.$axios({
        method: "post",
        url: "https://www.sparkgame.com.cn/api.php",
        data: this.qs.stringify({
          act: "get_list",
          mode: "banners"
        })
      })
        .then(response => {
          // console.log(response.data.result);
          this.isbanner = response.data.result; //返回的数据
        })
        .catch(error => {
          console.log(error); //请求失败返回的数据
        });
    },
    // 渲染最新游戏
    renderingGames() {
      this.$axios({
        method: "post",
        url: "https://www.sparkgame.com.cn/api.php",
        data: this.qs.stringify({
          act: "get_list",
          mode: "games",
          order_by: 1
        })
      })
        .then(response => {
          // console.log(response.data.result.list); //请求失败返回的数据
          console.log(response.data.result);
          this.isdata = response.data.result;
        })
        .catch(error => {
          console.log(error); //请求失败返回的数据
        });
    },
    //渲染资讯
    renderingNews() {
      this.$axios({
        method: "post",
        url: "https://www.sparkgame.com.cn/api.php",
        data: this.qs.stringify({
          act: "get_list",
          mode: "news"
        })
      })
        .then(response => {
          // console.log(response.data.result.list); //请求失败返回的数据
          // console.log(response.data.result);
          this.isNews = response.data.result;
        })
        .catch(error => {
          console.log(error); //请求失败返回的数据
          console.log("戳我"); //请求失败返回的数据
        });
    },
    //渲染加入我们
    renderingJoin_us() {
      this.$axios({
        method: "post",
        url: "https://www.sparkgame.com.cn/api.php",
        data: this.qs.stringify({
          act: "get_list",
          mode: "join_us"
        })
      })
        .then(response => {
          // console.log(response.data.result.list); //请求失败返回的数据
          // console.log(response.data.result);
          this.isjoin_us = response.data.result;
        })
        .catch(error => {
          console.log(error); //请求失败返回的数据
        });
    }
  },
  computed: {},
  created() {
    window.addEventListener("resize", this.getwidht);
    // this.getwidht()
  },
  // watch:{
  //   isdata(){
  //   this.$nextTick(() => { // 在dom渲染完后,再执行动画
  //                 //  var wow = new WOW({live: false})wow.init()})
  //                    var wow = new WOW({
  //                      live: false
  //                      })
  //                      wow.init()
  //                      })
  //   }
  // },
  destroyed() {
    window.removeEventListener("resize", this.getwidht);
  },
  mounted() {
    this.renderingBanner(); //渲染图片
    this.renderingGames(); //渲染游戏
    this.renderingNews(); //渲染资讯
    this.renderingJoin_us(); //加入我们

    this.getwidht();
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true
    });
    wow.init();
  },
  created() {
    this.loading = false;
  }
};
</script>
<style lang="scss">
.isIndex {
  .el-button {
    border-radius: 0px;
  }
  .magin0 {
    margin: 0px;
  }
  .row-bg {
    margin-left: 0px;
    margin-right: 0px;
  }
  font-family: "Helvetica", sans-serif;
  .siziimg {
    position: absolute;
    top: 25%;
    width: 100%;
    img {
      //         position: relative ;
      //  top:20%;
      margin: 0 auto;
      max-width: 100%;
    }
  }
  .gamstetit {
    width: 100%;
    margin: 2rem 0px;
    .en {
      font-size: 1rem;
      color: #101010;
      font-family: "Helvetica", sans-serif;
    }
    .zh {
      font-size: 1.5rem;
      color: #101010;
      font-family: "FZLTCHJW", sans-serif;
      font-weight: bold;
    }
  }
  .isgemscent {
    background: #e7e7e7;
  }
  .isNews {
    background: #eeeeee;
  }
  .isbj2 {
    margin-top: 50px;
    width: 100%;
    // background: url("~@/assets/bjzhes.jpg") center center no-repeat;
  }
  .triangle_left {
    width: 0;
    height: 0;
    border-top: 25px solid transparent;
    border-left: 10rem solid #ff3f40;
    border-bottom: 0px solid transparent;
  }
  .swiper-button-prev_q {
    background: url("~@/assets/193.png") center center no-repeat;
    left: -30px;
    right: auto;
    background-size: cover;
    width: 80px;
    height: 80px;
  }
  .swiper-button-next {
    background: url("~@/assets/194.png") center center no-repeat;
    right: -30px;
    left: auto;
    background-size: cover;
    width: 80px;
    height: 80px;
  }
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 4px;
  height: 15px;
  display: inline-block;
  border-radius: 8px;
  background: #fff;
}
.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet-active {
  height: 20px;
  background: #ff3f40;
}
.twozi {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.map {
  width: 100%;
  height: 400px;
}
.sample {
  width: 30px;
  height: 30px;
  // line-height: 40px;
  // background: rgba(0,0,0,0.5);
  // overflow: hidden;
  // box-shadow: 0 0 5px #000;
  // color: #fff;
  text-align: center;
  // padding: 10px;
  position: absolute;
}
.sample.active {
  // background: rgba(0,0,0,0.75);
  // color: #fff;
  img {
    width: 100%;
  }
}
</style>
