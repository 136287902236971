<template>
  <div class="isfooter">
    <el-row style="padding:10px;">
      <el-col
        :xs="12"
        :sm="12"
        :md="12"
        :lg="8"
        :xl="8"
        :offset="1"
        justify="center"
        style="color:#fff;text-align: left;"
      >
        <div style="margin-top:30px;">
          <img
            src="@/assets/colologo.png"
            alt
            height="50px"
            class="wow fadeInLeft"
            ata-wow-delay="300ms"
            data-wow-iteration="1"
            data-wow-duration="1s"
          />
          <div
            style="margin-top:10px;"
            class="wow fadeInLeft"
            ata-wow-delay="300ms"
            data-wow-iteration="1"
            data-wow-duration="2s"
          >
            中国 广东省 深圳市 南山区 深圳湾科技生态园2栋C座
          </div>
          <div
            style="padding-top:20px;"
            class="wow fadeInLeft"
            ata-wow-delay="300ms"
            data-wow-iteration="1"
            data-wow-duration="3s"
          >
            spark@sparkgame.com.cn
            <!-- <br />+44 654 5567 -->
          </div>
        </div>
      </el-col>
      <el-col
        :xs="12"
        :sm="12"
        :md="12"
        :lg="12"
        :xl="12"
        justify="center"
        style="color:#fff;text-align: right;"
        class="hidden-sm-and-down"
      >
        <div style="padding-top:95px">
          <a href="/"> 首页 &nbsp;&nbsp;&nbsp;&nbsp;</a>| &nbsp;
          <a href="/" @click="jump('1')"> 最新游戏 &nbsp;&nbsp;&nbsp;&nbsp;</a>|
          &nbsp; <a @click="jumplins"> 动态 &nbsp;&nbsp;&nbsp;&nbsp;</a>| &nbsp;
          <a href="/" @click="jump('3')"> 关于我们 &nbsp;&nbsp;&nbsp;&nbsp;</a>|
          &nbsp;
          <a href="/" @click="jump('4')"> 加入我们 &nbsp;&nbsp;&nbsp;&nbsp;</a>|
          &nbsp;
          <a href="/" @click="jump('5')"> 联系我们 &nbsp;&nbsp;&nbsp;&nbsp;</a>
        </div>
        <div style="margin-top:15px;color:#4a4a4a; font-size: 0.9rem;">
          <a href="https://beian.miit.gov.cn/">
                   粤ICP备19146953号-1
          </a>
   
        </div>
      </el-col>
      <!-- <el-col
        :xs="10"
        :sm="10"
        :md="10"
        :lg="6"
        :xl="6"
        justify="center"
        style="color:#fff;text-align: center;"
        class="hidden-sm-and-down"
      >
        <div
          style="margin-top:30px;"
          class="wow fadeIn"
          ata-wow-delay="300ms"
          data-wow-iteration="1"
          data-wow-duration="3s"
        >
          <div style="margin-top:10px;">BOLG</div>
          <div style="padding-top:40px;text-align: center;">
            <Ul>
              <li @click="gohone">首页</li>
              <li @click="jump">动态</li>
              <li >
                <a href="baidu.com">
                加入我们
                </a>
                </li>
            </Ul>
          </div>
        </div>
      </el-col> -->
      <!-- <el-col
        :xs="10"
        :sm="10"
        :md="10"
        :lg="8"
        :xl="8"
        justify="center"
        style="color:#fff;text-align: center;  background: #383838; "
        class="wow fadeIn"
        ata-wow-delay="300ms"
        data-wow-iteration="1"
        data-wow-duration="3s"
      >
        <div style="margin-top:30px;">
          <div style="margin-top:10px;">友情链接</div>
          <div style="padding-top:10px;">
            <Ul>
              <li class="hezuo" >
                <a href="baidu.com"> 百度</a>
               </li>
              <li class="hezuo">谷歌</li>
              <li class="hezuo">
                 <a href="https://weibo.com/"> 微博</a></li>
            </Ul>
          </div>
        </div>
      </el-col> -->
    </el-row>
  </div>
</template>
<script>
export default {
  name: "isfooter",
  methods: {
    gohone() {
      this.$router.push({ path: "/" });
      // this.$router.go(-1)
    },
    jumplins() {
      this.$router.push({ name: "islist" });
    },
    jump(index) {
      // console.log(index);
      const returnEle = document.querySelector("#anchor-" + index);
      if (returnEle) {
        returnEle.scrollIntoView({ behavior: "smooth" });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.isfooter {
  background: #383838;
  width: 100%;
  height: 200px;
  li {
    list-style-type: none;
    padding: 10px 0px;
  }
  .hezuo {
    display: inline-block;
    padding: 10px;
  }
  a {
    color: #fff;
    text-decoration: none;
  }
}
</style>
