import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import VueAwesomeSwiper from "vue-awesome-swiper";
import axios from "axios";
import qs from "qs";
// import "lib-flexible/flexible.js";
// import "lib-flexible";
// require styles
import "swiper/dist/css/swiper.css";
import "wowjs/css/libs/animate.css";
Vue.prototype.$axios = axios;
Vue.prototype.qs = qs;
Vue.use(VueAwesomeSwiper);
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
